import Vue from 'vue';
import VueResource from "vue-resource";
import config from '@/config';

Vue.use(VueResource);

config.setapikey();

export const clientService = {
  get,
  save,
}

function get(id) {
    let url = config.api + `/v1/admin/client/get?id=${id}`;
    
    var ret = new Promise(
    function (resolve, reject) {
        Vue.http.get(url).then(res => {
        resolve(res.body);
        }, err => {
        reject({
            error: true,
            msg: err
        });
        });
    }
  );
  return ret;
}

function save(loggedId, id, email, ddd_celular, celular) {
    let url = config.api + `/v1/admin/client/save`;

    let obj = {
        loggedId,
        id: id != undefined && id != null ? id : '',
        email,
        ddd_celular,
        celular,
    };

    var ret = new Promise(
        function (resolve, reject) {
            Vue.http.post(url, obj, { emulateJSON: true }).then(res => {
                resolve(res.body);
            }, err => {
                reject({
                    error: true,
                    msg: err
                });
            });    
        }
    );
    return ret;
}
